import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Footer from './components/Footer';
import Navbar from './components/Navbar';
import { AuthProvider } from './context/AuthContext';
import { CartProvider } from './context/CartContext';
import { CustomerProvider } from './context/CustomerContext';
import { OrderProvider } from './context/OrderContext'; // Import OrderProvider
import { ProductProvider } from './context/ProductContext'; // Import ProductProvider
import { ReviewProvider } from './context/ReviewContext';
import About from './pages/About';
import ContactUs from './pages/ContactUs';
import Home from './pages/Home';
import Orders from './pages/Orders';
import ProductDetails from './pages/ProductDetails';
import Products from './pages/Products';
import Signup from './pages/Signup';
import './css/App.css';

function App() {
  return (
    <ProductProvider>
      <CustomerProvider>
        <AuthProvider>
          <CartProvider>
            <OrderProvider>
              <ReviewProvider>
                <Navbar />
                <main>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/product/:id" element={<ProductDetails />} />
                    <Route path="/orders" element={<Orders />} />
                  </Routes>
                </main>
                <Footer />
              </ReviewProvider>
            </OrderProvider>
          </CartProvider>
        </AuthProvider>
      </CustomerProvider>
    </ProductProvider>
  );
}

export default App;
