// src/components/WhyChooseUs.js
import React from 'react';

import '../css/WhyChooseUs.css'; // Import CSS for styling
import MyCard from './MyCard';

const WhatWeAreOffering = () => {
  const reasons = [
    {
      title: 'Quality Products',
      description:
        'We ensure that our products meet the highest quality standards.',
      image: 'https://via.placeholder.com/250', // Placeholder image
    },
    {
      title: 'Customer Satisfaction',
      description:
        'Our customers are our priority. We strive to provide the best service.',
      image: 'https://via.placeholder.com/250',
    },
    {
      title: 'Affordable Prices',
      description:
        'We offer competitive pricing without compromising on quality.',
      image: 'https://via.placeholder.com/250',
    },
  ];

  return (
    <div className="why-choose-us">
      <h4>WHAT WE DO</h4>
      <h2>What we are offering?</h2>
      <div className="reasons-container">
        {reasons.map((reason, index) => (
          <MyCard reason={reason} key={index} />
        ))}
      </div>
    </div>
  );
};

export default WhatWeAreOffering;
