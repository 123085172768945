import React from 'react';

import ProductCard from '../components/ProductCard';
import { useProducts } from '../context/ProductContext';

function Products() {
  const { products } = useProducts();
  return (
    <div className="products-page-container" style={{ padding: '5px 20px' }}>
      <h2>Our Products</h2>
      <div className="products-page-product-list">
        {Object.values(products).map((product) => {
          return <ProductCard key={product.id} productId={product.id} />;
        })}
      </div>
    </div>
  );
}

export default Products;
