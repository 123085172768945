import PropTypes from 'prop-types';
import React, { createContext, useState, useContext } from 'react';

const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
  const [customer, setCustomer] = useState(() => {
    const storedCustomer = localStorage.getItem('customer');
    return storedCustomer ? JSON.parse(storedCustomer) : null;
  });
  const [errorMessage, setErrorMessage] = useState('');
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const validateMobileNumber = (number) => {
    const regex = /^\d{10}$/;
    return regex.test(number);
  };

  const requestOtp = async (number) => {
    if (!validateMobileNumber(number)) {
      setErrorMessage('Invalid mobile number');
      return;
    }
    try {
      const response = await fetch(
        `${apiBaseUrl}/products/api/request-otp/${number}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to send OTP');
      }

      setErrorMessage('');
      return true;
    } catch (error) {
      setErrorMessage(error.message);
      return false;
    }
  };

  const verifyOtp = async (number, otp) => {
    try {
      const response = await fetch(`${apiBaseUrl}/products/api/verify-otp/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ number, otp }),
      });
      if (!response.ok) {
        throw new Error('Invalid OTP');
      }

      const data = await response.json();
      setCustomer(data);
      localStorage.setItem('customer', JSON.stringify(data));
      return true;
    } catch (error) {
      setErrorMessage(error.message);
      return false;
    }
  };

  return (
    <CustomerContext.Provider
      value={{
        customer,
        setCustomer,
        requestOtp,
        verifyOtp,
        errorMessage,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = () => useContext(CustomerContext);

CustomerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
