import React from 'react';
import { FaStar } from 'react-icons/fa';
import Rating from 'react-rating';
import '../css/StarRating.css';

const StarRating = ({ rating, setRating, size = 20, readonly = true }) => {
  const handleRatingChange = (newRating) => {
    if (!readonly) {
      setRating(Math.ceil(newRating));
    }
  };

  return (
    <div className="star-rating">
      <Rating
        initialRating={rating}
        readonly={readonly} // Disable interaction when readonly is true
        emptySymbol={<FaStar style={{ color: '#d3d3d3', fontSize: size }} />}
        fullSymbol={<FaStar style={{ color: '#FFD700', fontSize: size }} />}
        onClick={handleRatingChange} // Handle click to select stars
        stop={5} // Limit to 5 stars
        fractions={1} // Only allow complete stars to be selected
      />
    </div>
  );
};

export default StarRating;
