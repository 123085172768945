import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useEffect } from 'react';

import { useAuth } from './AuthContext';
import { useCustomer } from './CustomerContext';
import { useProducts } from './ProductContext';

const CartContext = createContext();
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [isCart, setShowCart] = useState(false);
  const { retrieveIdsFromHash } = useProducts();
  const { isLoggedIn } = useAuth();
  const { customer } = useCustomer();
  const [quantities, setQuantities] = useState(new Map());

  useEffect(() => {
    const loadCart = async () => {
      if (isLoggedIn) {
        try {
          const response = await fetch(
            `${API_BASE_URL}/products/api/${customer.id}/fetch-cart/`
          );
          if (!response.ok) {
            throw new Error('Failed to fetch cart');
          }
          const data = await response.json();

          if (Object.keys(data.products_quantity).length === 0) {
            setQuantities(new Map());
            setCartItems([]);
          } else {
            const newQuantities = new Map(
              Object.entries(data.products_quantity).map(([key, value]) => [
                parseInt(key, 10),
                parseInt(value, 10),
              ])
            );
            setQuantities(newQuantities);
            setCartItems(Array.from(newQuantities.keys()));
          }
        } catch (error) {
          console.error('Failed to fetch cart from server:', error);
        }
      } else {
        const storedCart = localStorage.getItem('cart');
        const parsedCart = storedCart
          ? new Map(JSON.parse(storedCart))
          : new Map();
        setQuantities(parsedCart);
        setCartItems(Array.from(parsedCart.keys()));
      }
    };

    loadCart();
  }, [customer]);

  const addToCart = async (hash, quantity = 1) => {
    const ids = retrieveIdsFromHash(hash);
    if (isLoggedIn) {
      try {
        const response = await fetch(
          `${API_BASE_URL}/products/api/add-to-cart/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              product_id: ids.productId,
              variant_id: ids.variantId,
              quantity,
              customer_id: customer.id,
            }),
          }
        );

        if (!response.ok) {
          throw new Error('Failed to add to cart');
        }
      } catch (error) {
        console.error('Error adding to cart:', error);
      }
    }
    const newQuantities = new Map(quantities);
    const currentQuantity = getQuantity(hash);
    newQuantities.set(hash, currentQuantity + quantity);
    setQuantities(newQuantities);
    setCartItems(Array.from(newQuantities.keys()));
    localStorage.setItem(
      'cart',
      JSON.stringify(Array.from(newQuantities.entries()))
    );
  };

  const removeFromCart = async (hash, quantity = 1) => {
    const ids = retrieveIdsFromHash(hash);
    if (isLoggedIn) {
      try {
        const response = await fetch(
          `${API_BASE_URL}/products/api/remove-from-cart/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              product_id: ids.productId,
              variant_id: ids.variantId,
              quantity,
              customer_id: customer.id,
            }),
          }
        );

        if (!response.ok) {
          throw new Error('Failed to remove from cart');
        }
      } catch (error) {
        console.error('Error removing from cart:', error);
      }
    }
    const newQuantities = new Map(quantities);
    const currentQuantity = getQuantity(hash);
    const updatedQuantity = currentQuantity - quantity;
    if (updatedQuantity <= 0) {
      newQuantities.delete(hash);
    } else {
      newQuantities.set(hash, updatedQuantity);
    }
    setQuantities(newQuantities);
    setCartItems(Array.from(newQuantities.keys()));
    localStorage.setItem(
      'cart',
      JSON.stringify(Array.from(newQuantities.entries()))
    );
  };

  const isInCart = (hash) => quantities.has(hash);

  const clearCart = () => {
    setQuantities(new Map());
    setCartItems([]);
    localStorage.removeItem('cart');
  };

  const getQuantity = (hash) => quantities.get(hash) || 0;

  return (
    <CartContext.Provider
      value={{
        quantities,
        cartItems,
        isCart,
        getQuantity,
        addToCart,
        removeFromCart,
        clearCart,
        isInCart,
        setShowCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
