import React, { useState, useEffect } from 'react';
import {
  FaSearch,
  FaShoppingCart,
  FaUser,
  FaBox,
  FaSignOutAlt,
  FaBars, // Add FaBars for the menu icon
} from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import '../css/Navbar.css';
import { useAuth } from '../context/AuthContext';
import { useCart } from '../context/CartContext';
import { useCustomer } from '../context/CustomerContext';
import { useProducts } from '../context/ProductContext';
import logo from '../img/logo.png';

import CartDrawer from './CartDrawer';

const Navbar = () => {
  const { isLoggedIn, logout } = useAuth();
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const { isCart, setShowCart } = useCart();
  const { setCustomer } = useCustomer();
  const { products } = useProducts();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for toggling mobile menu
  const [isSeachVisible, setSearchIsVisible] = useState(false); // Initial state is hidden
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = (path) => (location.pathname === path ? 'active' : '');
  const noBannerPaths = [
    '/product/',
    '/signup',
    '/contact',
    '/products',
    '/orders',
  ];
  const isNonBannerPage = noBannerPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  const handleLogout = () => {
    logout();
    setCustomer(null);
    setShowLogoutDialog(false);
    navigate('/');
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsScrolled(!(searchQuery.length === 0));
  }, [searchQuery]);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
      const results = products
        .filter((product) =>
          product.name.toLowerCase().includes(query.toLowerCase())
        )
        .slice(0, 3);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const handleResultClick = (id) => {
    navigate(`/product/${id}`);
    setSearchResults([]);
    setSearchQuery('');
  };

  const handleMenuButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsScrolled(!isMenuOpen);
  };

  const renderNavLink = (path, label) => (
    <Link
      className={`nav-link ${isActive(path)}`}
      aria-current={location.pathname === path ? 'page' : undefined}
      to={path}
    >
      {label}
    </Link>
  );

  return (
    <>
      <nav
        className={`navbar ${isScrolled ? 'scrolled' : ''} ${isNonBannerPage ? 'nonbanner' : ''}`}
      >
        {/* Menu Button for Mobile */}
        <button className="menu-button" onClick={handleMenuButtonClick}>
          <FaBars size={24} />
        </button>

        {/* Logo Section */}
        <Link
          to="/"
          className={`navbar-logo ${isSeachVisible ? 'invisible' : ''}`}
        >
          <img src={logo} alt="Logo" className="navbar-logo-img" />
          <h1 className="navbar-logo-title">
            <span className="navbar-setia-text">Setia&#39;s</span>
            <span className="navbar-naroi-text">Naroi&nbsp;</span>
            <span className="navbar-sehat-text">Sehat</span>
          </h1>
        </Link>

        {/* Main Navigation Links (Collapsible) */}
        <div className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
          {renderNavLink('/', 'Home')}
          {renderNavLink('/about', 'About')}
          {renderNavLink('/products', 'Products')}
          {renderNavLink('/contact', 'Contact')}
        </div>

        {/* Search Form & Navbar Icons (Outside Collapse) */}
        <div className="navbar-search">
          <input
            className={`navbar-search-input ${isSeachVisible ? 'visible' : ''}`}
            type="search"
            placeholder="Search here..."
            value={searchQuery}
            onChange={handleSearch}
            required
          />

          <div className="navbar-icons">
            <button
              className="icon-button"
              onClick={() => setSearchIsVisible(!isSeachVisible)}
            >
              <FaSearch size={20} />
            </button>
            <Link
              to={isLoggedIn ? '/orders' : '/signup'}
              className="icon-button"
            >
              <FaBox size={24} />
            </Link>
            {isLoggedIn ? (
              <div className="logout-container">
                <FaSignOutAlt
                  className="icon-button"
                  size={24}
                  onClick={() => setShowLogoutDialog(!showLogoutDialog)}
                />
                {showLogoutDialog && (
                  <div className="logout-dialog" onClick={handleLogout}>
                    Logout
                  </div>
                )}
              </div>
            ) : (
              <Link to="/signup" className="icon-button">
                <FaUser size={24} />
              </Link>
            )}
            <button
              className="icon-button"
              onClick={() => setShowCart(!isCart)}
            >
              <FaShoppingCart size={24} />
            </button>
          </div>
        </div>

        {/* Search Results */}
        {searchResults.length > 0 && (
          <div className="search-results">
            {searchResults.map((product) => (
              <div
                key={product.id}
                className="search-result-item"
                onClick={() => handleResultClick(product.id)}
              >
                <img src={product.image} alt={product.name} />
                <span>{product.name}</span>
              </div>
            ))}
          </div>
        )}
      </nav>
      <CartDrawer show={isCart} />
    </>
  );
};

export default Navbar;
