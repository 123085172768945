import React from 'react';

import Banner from './Banner';
import '../css/Testimonial.css';

const Testimonials = () => {
  const testimonials = [
    {
      src: 'https://via.placeholder.com/300x250?text=Testimonial+1',
      type: 'image',
      overlayComponent: (
        <div className="testimonial-overlay">
          <h2>Testimonials</h2>
          <p>"This product changed my life!"</p>
          <p>- John Doe</p>
        </div>
      ),
    },
    {
      src: 'https://via.placeholder.com/300x250?text=Testimonial+2',
      type: 'image',
      overlayComponent: (
        <div className="testimonial-overlay">
          <h2>Testimonials</h2>
          <p>"Amazing quality and service!"</p>
          <p>- Jane Smith</p>
        </div>
      ),
    },
    {
      src: 'https://via.placeholder.com/300x250?text=Testimonial+3',
      type: 'image',
      overlayComponent: (
        <div className="testimonial-overlay">
          <h2>Testimonials</h2>
          <p>"I will definitely recommend it!"</p>
          <p>- Alex Johnson</p>
        </div>
      ),
    },
    {
      src: 'https://via.placeholder.com/300x250?text=Testimonial+4',
      type: 'image',
      overlayComponent: (
        <div className="testimonial-overlay">
          <h2>Testimonials</h2>
          <p>"Best experience ever!"</p>
          <p>- Maria Garcia</p>
        </div>
      ),
    },
    {
      src: 'https://via.placeholder.com/300x250?text=Testimonial+5',
      type: 'image',
      overlayComponent: (
        <div className="testimonial-overlay">
          <h2>Testimonials</h2>
          <p>"Totally satisfied with my purchase!"</p>
          <p>- Tom Brown</p>
        </div>
      ),
    },
  ];

  return (
    <section className="testimonials-section">
      <Banner mediaList={testimonials} height={'70vh'} />
    </section>
  );
};

export default Testimonials;
