import PropTypes from 'prop-types'; // Import PropTypes for props validation
import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

import '../css/Collapsible.css';

const Collapsible = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <div className="collapsible-title" onClick={toggleCollapsible}>
        <h2>{title}</h2>
        {isOpen ? <FaMinus /> : <FaPlus />}
      </div>
      <div className={`collapsible-content ${isOpen ? 'open' : ''}`}>
        {children}
      </div>
    </div>
  );
};

// Add PropTypes for prop validation
Collapsible.propTypes = {
  title: PropTypes.string.isRequired, // Ensures title is a required string
  children: PropTypes.node.isRequired, // Ensures children are provided
};

export default Collapsible;
