import React from 'react';
import '../css/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h4 className="footer-heading">Quick Links</h4>
          <ul className="footer-links">
            <li>
              <a href="/about">About Us</a>
            </li>
            <li>
              <a href="/services">Services</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
            <li>
              <a href="/terms">Terms & Conditions</a>
            </li>
            <li>
              <a href="/support">Support</a>
            </li>
          </ul>
        </div>

        <div className="footer-column">
          <h4 className="footer-heading">Address</h4>
          <p> 123 Street, New York, USA </p>
          <p> +012 345 67890 </p>
          <p> admin@naroisehat.in </p>
        </div>

        <div className="footer-column">
          <h4 className="footer-heading">Subscribe to Our Newsletter</h4>
          <p>
            Stay updated with our latest news and offers. Enter your email below
            to subscribe:
          </p>
          <div className="subscribe-form">
            <input
              className="newsletter-input"
              type="email"
              placeholder="Your email"
            />
            <button type="button" className="subscribe-button">
              Sign Up
            </button>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>
          &copy; {new Date().getFullYear()} Naroi Sehat. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
