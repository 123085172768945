import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useProducts } from '../context/ProductContext';

import Collapsible from './Collapsible';


import '../css/OrderCard.css';

const OrderCard = ({ order }) => {
  const [product, setProduct] = useState(null);
  const [variant, setVariant] = useState(null);
  const { getProductById } = useProducts();

  useEffect(() => {
    const fetchProduct = async () => {
      const product = await getProductById(order.product_id);
      const variant = product
        ? product.variants.find((variant) => variant.id === order.variant_id)
        : null;
      setProduct(product);
      setVariant(variant);
    };
    fetchProduct();
  }, [order]);

  return (
    <div className="order-card">
      {product && variant && (
        <Collapsible
          title={
            <div className="order-card-title">
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}${variant.images[0].image}`}
                alt="Product"
                className="order-card-image"
              />
              <div className="order-card-text">
                <span className="order-card-name">{product.name}</span>
                <p className="order-card-description">{product.description}</p>
              </div>
            </div>
          }
        >
          <div className="order-details">
            <p>
              <strong>Quantity:</strong> {order.quantity}
            </p>
            <p>
              <strong>Total Price:</strong> Rs. {order.total_price}
            </p>
            <p>
              <strong>Status:</strong> {order.status}
            </p>
            <p>
              <strong>Delivery Time:</strong>{' '}
              {new Date(order.delivery_time).toLocaleString()}
            </p>
            <p>
              <strong>Payment Method:</strong> {order.payment_method}
            </p>
            <p>
              <strong>Created Time:</strong>{' '}
              {new Date(order.created_time).toLocaleString()}
            </p>
          </div>
        </Collapsible>
      )}
    </div>
  );
};

export default OrderCard;
