import PropTypes from 'prop-types';
import React from 'react';
import { FaPlus, FaMinus, FaTrash } from 'react-icons/fa';

import { useCart } from '../context/CartContext'; // Import CartContext

import '../css/QuantityHandling.css';

const QuantityHandling = ({ hash }) => {
  const { removeFromCart, addToCart, getQuantity } = useCart(); // Access cart items from CartContext

  const quantity = getQuantity(hash);

  const handleInputChange = (e) => {
    const value = Number(e.target.value);
    if (value >= 1) {
      if (value - quantity > 0) {addToCart(hash, value - quantity);}
      else {removeFromCart(hash, quantity - value);}
    }
  };

  return (
    <div className="quantity">
      {quantity === 1 ? (
        <button
          className="quantity-modifier"
          onClick={() => removeFromCart(hash)}
        >
          <FaTrash />
        </button>
      ) : (
        <button
          className="quantity-modifier"
          onClick={() => removeFromCart(hash)}
        >
          <FaMinus />
        </button>
      )}
      <input
        type="number"
        value={quantity}
        onChange={handleInputChange}
        min="1"
        className="number"
      />
      <button className="quantity-modifier" onClick={() => addToCart(hash)}>
        <FaPlus />
      </button>
    </div>
  );
};

QuantityHandling.propTypes = {
  hash: PropTypes.number.isRequired,
};

export default QuantityHandling;
