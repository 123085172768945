// src/pages/About.js
import React from 'react';

import Banner from '../components/Banner';
import video from '../img/Agrowfarm.mp4';
import image1 from '../img/image1.jpeg';
import '../css/About.css';
import WhatWeAreOffering from '../components/WhatWeAreOffering';

function About() {
  const mediaList = [
    { src: video, type: 'video' }, // Adding video
  ];

  return (
    <>
      <Banner mediaList={mediaList} height={'60vh'} />

      {/* Div with additional image */}
      <div className="about-image-container">
        <img src={image1} alt="About Image" className="about-image" />
        <p>
          Lorem ipsum odor amet, consectetuer adipiscing elit. Ex nullam id
          aliquet pellentesque porta orci maximus porta arcu. Donec efficitur
          urna sociosqu cras pellentesque aliquet malesuada. Laoreet justo
          rutrum nulla; dolor finibus vehicula. Pellentesque urna litora
          maecenas dis dui curabitur pharetra a. Torquent ac himenaeos
          suspendisse pulvinar himenaeos efficitur nunc mauris. Et cras primis
          sed phasellus quam potenti integer tristique sollicitudin? Integer ad
          diam laoreet imperdiet consectetur nec massa ad placerat. Amet id odio
          elit, ornare erat vulputate erat ultrices ornare. Libero augue ipsum
          libero duis metus. Rutrum montes rutrum gravida cubilia ex ac aenean.
          Eu volutpat lectus velit litora aliquet turpis.
        </p>
        <p>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est
          laborum.
        </p>
        <p>
          Curabitur pretium tincidunt lacus. Nulla gravida orci a odio. Nullam
          varius, turpis et commodo pharetra, est eros bibendum elit, nec luctus
          magna felis sollicitudin mauris.
        </p>
      </div>

      <WhatWeAreOffering />
    </>
  );
}

export default About;
