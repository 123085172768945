import PropTypes from 'prop-types';
import React from 'react';

import { useCart } from '../context/CartContext';

import QuantityHandling from './QuantityHandling';

import '../css/AddToCartButton.css';

const AddToCartButton = ({ hash }) => {
  const { setShowCart, addToCart, isInCart } = useCart();

  return isInCart(hash) ? (
    <div className="go-to-cart">
      <button className="go-to-cart-button" onClick={() => setShowCart(true)}>
        Go To Cart
      </button>
      <QuantityHandling hash={hash} />
    </div>
  ) : (
    <button className="add-to-cart-button" onClick={() => addToCart(hash)}>
      Add to Cart
    </button>
  );
};

AddToCartButton.propTypes = {
  hash: PropTypes.number.isRequired,
};

export default AddToCartButton;
