// src/components/WhyChooseUs.js
import React from 'react';
import '../css/MyCard.css';

const MyCard = ({ reason }) => {
  return (
    <div className="reason-card">
      <img
        src={reason.image}
        alt={`Image for ${reason.title}`}
        className="reason-image"
      />
      <div className="title-box">
        <h3>{reason.title}</h3>
      </div>
      <p>{reason.description}</p>
    </div>
  );
};

export default MyCard;
