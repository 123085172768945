import React, { useState } from 'react';
import '../css/ContactUs.css';
import { FaBuilding } from 'react-icons/fa'; // If you want to use it, you can add it in JSX

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact_number: '',
    query: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    setIsSubmitting(true);
    try {
      const response = await fetch(`${API_BASE_URL}/products/api/contact-us/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setResponseMessage('Your query has been submitted successfully!');
        setFormData({ name: '', email: '', contact_number: '', query: '' });
      } else {
        setResponseMessage('There was an error submitting your query.');
      }
    } catch (error) {
      setResponseMessage('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contactUs-container">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit} className="form">
        <div className="input-group">
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="input"
            placeholder="Name"
          />
        </div>

        <div className="input-group">
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="input"
            placeholder="Email"
          />
        </div>

        <div className="input-group">
          <input
            type="tel"
            id="contact_number"
            name="contact_number"
            value={formData.contact_number}
            onChange={handleChange}
            required
            className="input"
            placeholder="Contact Number"
          />
        </div>

        <div className="input-group">
          <textarea
            id="query"
            name="query"
            value={formData.query}
            onChange={handleChange}
            required
            className="textarea"
            placeholder="Query"
          ></textarea>
        </div>

        <button type="submit" className="query-button" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      {responseMessage && <p>{responseMessage}</p>}
    </div>
  );
};

export default ContactUs;
