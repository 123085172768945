import PropTypes from 'prop-types';
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import { useCart } from '../context/CartContext';
import { useOrders } from '../context/OrderContext';
import { useProducts } from '../context/ProductContext';

import QuantityHandling from './QuantityHandling';
import '../css/CartDrawer.css';

const CartDrawer = ({ show }) => {
  const { cartItems, removeFromCart, setShowCart, clearCart, getQuantity } =
    useCart();
  const { submitOrder } = useOrders();
  const { getProductById, retrieveIdsFromHash } = useProducts();
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const totalPrice = cartItems.reduce((total, hash) => {
    const ids = retrieveIdsFromHash(hash);
    const product = getProductById(ids.productId);
    const variant = product
      ? product.variants.find((variant) => variant.id === ids.variantId)
      : null;
    const quantity = getQuantity(hash);
    return product && variant ? total + variant.price * quantity : total;
  }, 0);

  const handleOrderSubmission = async () => {
    if (!isLoggedIn) {
      navigate('/signup');
      setShowCart(false);
      return;
    }
    submitOrder();
    clearCart();
    setShowCart(false);
  };

  const isCartEmpty =
    cartItems.length === 0 || (cartItems.length === 1 && cartItems[0] === null);

  return (
    <>
      {show && <div className="backdrop" onClick={() => setShowCart(false)} />}
      <div className={`cart-drawer ${show ? 'open' : ''}`}>
        <div className="cart-drawer-header">
          <h2>Your Cart</h2>
          <button onClick={() => setShowCart(false)} className="close-button">
            <FaTimes />
          </button>
        </div>
        <div className="cart-drawer-content">
          {isCartEmpty ? (
            <p>Your cart is empty.</p>
          ) : (
            cartItems.map((hash) => {
              const ids = retrieveIdsFromHash(hash);
              const product = getProductById(ids.productId);
              const variant = product
                ? product.variants.find(
                  (variant) => variant.id === ids.variantId
                )
                : null;

              if (!product || !variant) {
                return null; // Skip rendering if product or variant is not found
              }

              return (
                <div key={hash} className="cart-item">
                  <img
                    src={`${process.env.REACT_APP_API_BASE_URL}${variant.images[0].image}`}
                    alt={product.name}
                    className="cart-item-image"
                  />
                  <div className="cart-item-details">
                    <h3 className="cart-item-name">
                      {product.name} - {variant.weight}kg
                    </h3>
                    <p className="cart-item-weight">
                      Rs. {variant.price * getQuantity(hash)}
                    </p>
                    <QuantityHandling
                      hash={hash}
                      className="cart-drawer-quantity"
                    />
                  </div>
                  <button
                    className="cart-item-remove-button"
                    onClick={() => removeFromCart(hash, getQuantity(hash))}
                  >
                    Remove
                  </button>
                </div>
              );
            })
          )}
        </div>
        {!isCartEmpty && (
          <div className="cart-drawer-footer">
            <div className="cart-total">
              <span>Total: Rs. {totalPrice.toFixed(2)}</span>
            </div>
            <button
              className="proceed-to-buy-button"
              onClick={handleOrderSubmission}
            >
              Proceed to Buy
            </button>
          </div>
        )}
      </div>
    </>
  );
};

CartDrawer.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default CartDrawer;
