import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import '../css/ProductDetails.css';
import AddToCartButton from '../components/AddToCartButton';
import Collapsible from '../components/Collapsible';
import ReviewCard from '../components/ReviewCard';
import StarRating from '../components/StarRating';
import { useCart } from '../context/CartContext';
import { useProducts } from '../context/ProductContext';
import { useReviews } from '../context/ReviewContext';

const ProductDetails = () => {
  const { id } = useParams();
  const { getProductById, generateHash } = useProducts();
  const product = getProductById(Number(id));
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { setShowCart, addToCart } = useCart();
  const { reviews, fetchReviews, error } = useReviews();
  const [selectedVariant, setSelectedVariant] = useState(null);

  useEffect(() => {
    if (product && product.variants && product.variants.length > 0) {
      setSelectedVariant(product.variants[0]); // Set the default variant
    }
  }, [product]);

  useEffect(() => {
    const loadReviews = async () => {
      try {
        await fetchReviews(product.id);
      } catch (error) {
        console.error('Failed to load reviews:', error);
      }
    };

    if (product) {
      loadReviews();
    }
  }, [product]);

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleBuyNowClick = () => {
    addToCart(generateHash(product.id, selectedVariant.id));
    setShowCart(true);
  };

  const handleVariantChange = (e) => {
    const variantId = Number(e.target.value);
    const selected = product.variants.find(
      (variant) => variant.id === variantId
    );
    setSelectedVariant(selected);
  };

  return (
    <div className="product-details-container">
      <div className="product-details-image-container">
        <div className="thumbnail-container">
          {selectedVariant?.images.map((imgSrc, index) => (
            <img
              key={index}
              src={`${process.env.REACT_APP_API_BASE_URL}${imgSrc.image}`}
              alt={`Thumbnail ${index + 1}`}
              className={`thumbnail ${currentImageIndex === index ? 'active' : ''}`}
              onClick={() => setCurrentImageIndex(index)}
            />
          ))}
        </div>
        <div className="product-details-carousel-container">
          <img
            src={`${process.env.REACT_APP_API_BASE_URL}${selectedVariant?.images[currentImageIndex].image}`}
            alt={`Product image ${currentImageIndex + 1}`}
            className="product-details-image"
          />
          <div className="product-details-buttons">
            {selectedVariant ? (
              <>
                <AddToCartButton
                  hash={generateHash(product.id, selectedVariant.id)}
                />
                <button className="buy-now-button" onClick={handleBuyNowClick}>
                  Buy Now
                </button>
              </>
            ) : (
              <p>Select a variant to add to cart.</p>
            )}
          </div>
        </div>
      </div>
      <div className="product-details-text">
        <h1 className="product-name">{product.name}</h1>
        <p className="product-description">{product.description}</p>
        <h2 className="product-price">Rs. {selectedVariant?.price}</h2>

        {/* Variant Weight Dropdown */}
        <label htmlFor="variant-select">Select Weight: </label>
        <select
          id="variant-select"
          onChange={handleVariantChange}
          value={selectedVariant?.id}
        >
          {product.variants.map((variant) => (
            <option key={variant.id} value={variant.id}>
              {variant.weight}kg
            </option>
          ))}
        </select>

        {/* Product Rating */}
        <div className="product-reviews">
          <StarRating rating={product.average_rating} />
          <p className="reviews-count">({product.review_count} reviews)</p>
        </div>

        {/* Collapsible Sections */}
        <Collapsible title={'Benefits'}>
          <p>Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
        </Collapsible>
        <Collapsible title={'Description'}>
          <p>Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
        </Collapsible>
        <Collapsible title={'Ingredients'}>
          <p>Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
        </Collapsible>
        <Collapsible title={'Growing Process'}>
          <p>Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
        </Collapsible>
        <Collapsible title={'Return Policy'}>
          <p>Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
        </Collapsible>

        {/* Reviews Section */}
        <div className="review-cards">
          {error ? (
            <p>{error}</p>
          ) : reviews.length > 0 ? (
            reviews.map((review) => (
              <ReviewCard key={review.id} review={review} />
            ))
          ) : (
            <p>No reviews yet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
