import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

import { useCustomer } from './CustomerContext';
import { useProducts } from './ProductContext';

const ReviewContext = createContext();

export const ReviewProvider = ({ children }) => {
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(null);
  const { customer } = useCustomer();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchReviews = async (productId) => {
    try {
      const response = await fetch(
        `${apiBaseUrl}/products/api/${productId}/fetch-reviews/`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch reviews');
      }
      const data = await response.json();
      setReviews(data);
      return data; // Return data to be used elsewhere
    } catch (error) {
      setError(error.message);
      return []; // Return empty array in case of error
    }
  };

  const submitReview = async (productId, reviewData) => {
    try {
      const response = await fetch(
        `${apiBaseUrl}/products/api/submit-review/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            product_id: productId,
            customer_id: customer.id,
            ...reviewData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to submit review');
      }

      const data = await response.json();
      setReviews((prevReviews) => [...prevReviews, data]);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <ReviewContext.Provider
      value={{ reviews, fetchReviews, submitReview, error }}
    >
      {children}
    </ReviewContext.Provider>
  );
};

export const useReviews = () => useContext(ReviewContext);

ReviewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
