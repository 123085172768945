import PropTypes from 'prop-types'; // Import PropTypes for props validation
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useProducts } from '../context/ProductContext'; // Import useProducts hook

import AddToCartButton from './AddToCartButton';
import '../css/ProductCard.css';
import StarRating from './StarRating';

const ProductCard = ({ productId }) => {
  const { getProductById, generateHash } = useProducts();
  const product = getProductById(productId);
  const navigate = useNavigate();

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleCardClick = () => {
    navigate(`/product/${product.id}`);
  };

  return (
    <div className="product-card">
      <img
        src={`${process.env.REACT_APP_API_BASE_URL}${product.variants[0].images[0].image}`}
        className="product-card-image"
        alt="Product image"
      />
      <div className="product-card-details" onClick={handleCardClick}>
        <h2 className="product-card-name">{product.name}</h2>
        <p className="product-card-description">{product.description}</p>
        <p className="product-card-price">Rs. {product.variants[0].price}</p>
        <div className="product-card-weight-reviews">
          <p className="product-card-weight">
            Weight: {product.variants[0].weight}kg
          </p>
          <div className="product-card-ratings-reviews">
            <StarRating rating={product.average_rating} />
            <div className="product-card-reviews">{product.review_count}</div>
          </div>
        </div>
      </div>

      <AddToCartButton
        className="product-card-add-to-cart-button"
        hash={generateHash(product.id, product.variants[0].id)}
      />
    </div>
  );
};

ProductCard.propTypes = {
  productId: PropTypes.number.isRequired,
};

export default ProductCard;
