import PropTypes from 'prop-types';
import React, { useState } from 'react';
import '../css/Banner.css';

const Banner = ({ mediaList, height }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % mediaList.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + mediaList.length) % mediaList.length
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const showControls = mediaList.length > 1; // Show controls only if there's more than one item

  return (
    <div className="banner" style={{ height: height }}>
      <div className="banner-media-container">
        {mediaList.map((mediaItem, index) => {
          const isVideo = mediaItem.type === 'video';
          return (
            <div
              key={index}
              className={`banner-media ${index === currentIndex ? 'active' : ''}`}
              style={{ display: index === currentIndex ? 'block' : 'none' }} // Show only the current media
            >
              {isVideo ? (
                <video className="banner-media-element" autoPlay loop muted>
                  <source src={mediaItem.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  className="banner-media-element"
                  src={mediaItem.src}
                  alt={mediaItem.alt || 'Banner Image'}
                />
              )}
              {/* Overlay Section */}
              {mediaItem.overlayComponent && (
                <div className="banner-overlay" style={{ height: height }}>
                  {mediaItem.overlayComponent}{' '}
                  {/* Render the overlay component */}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {showControls && (
        <div className="banner-controls">
          <button
            className="banner-button prev"
            onClick={handlePrev}
            aria-label="Previous"
          >
            &#10094;
          </button>
          <div className="banner-dots">
            {mediaList.map((_, index) => (
              <span
                key={index}
                className={`banner-dot ${index === currentIndex ? 'active' : ''}`}
                onClick={() => handleDotClick(index)} // Navigate to the clicked dot
                aria-label={`Slide ${index + 1}`}
              ></span>
            ))}
          </div>
          <button
            className="banner-button next"
            onClick={handleNext}
            aria-label="Next"
          >
            &#10095;
          </button>
        </div>
      )}
    </div>
  );
};

Banner.propTypes = {
  mediaList: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired, // Media source (image or video)
      type: PropTypes.oneOf(['image', 'video']).isRequired, // Type of media
      alt: PropTypes.string, // Optional alt text for images
      overlayComponent: PropTypes.element, // React component to display on overlay
    })
  ).isRequired, // Require mediaList as a prop
  height: PropTypes.string, // Height of the banner (e.g., '400px' or '50vh')
};

Banner.defaultProps = {
  height: '400px', // Default height if none is provided
};

export default Banner;
