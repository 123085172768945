import React from 'react';

import Banner from '../components/Banner';
import ProductCard from '../components/ProductCard';
import '../css/Home.css';
import Testimonials from '../components/Testimonials';
import WhyChooseUs from '../components/WhyChooseUs';
import { useProducts } from '../context/ProductContext';
import video from '../img/Agrowfarm.mp4';
import image1 from '../img/image1.jpeg';

function Home() {
  const { products } = useProducts();

  const mediaList = [
    { src: video, type: 'video' },
    { src: image1, type: 'image' },
  ];

  return (
    <div className="home-page-container">
      <Banner mediaList={mediaList} height={'90vh'} />
      <WhyChooseUs />
      <h1 className="featured-products">Featured Products</h1>
      <div className="home-page-product-list">
        {Object.values(products).map((product) => {
          return <ProductCard key={product.id} productId={product.id} />;
        })}
      </div>
      <Testimonials />
    </div>
  );
}

export default Home;
