import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import '../css/ReviewCard.css';
import StarRating from './StarRating';

const ReviewCard = ({ review }) => {
  return (
    <div className="review-card">
      <h4>{review.customer_name}</h4>
      <StarRating rating={review.rating} />
      <h3>{review.heading}</h3>
      <p>{review.comment || 'No comment available'}</p>
      <div className="image-preview">
        {review.images.map((image, index) => (
          <img
            key={index}
            src={`${process.env.REACT_APP_API_BASE_URL}${image.image}`}
            alt={`Uploaded preview ${index + 1}`}
            className="small-image"
          />
        ))}
      </div>
      <div className="divider" />
    </div>
  );
};

ReviewCard.propTypes = {
  review: PropTypes.shape({
    customer_name: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    heading: PropTypes.string.isRequired,
    comment: PropTypes.string,
    created_time: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default ReviewCard;
